<template>
    <main>
		<HeaderTab 
            :title="$t('global.feed')"
        />

        <div id="content">
            <NotesFeed />
        </div>
	</main>
</template>


<script type="text/javascript">
	export default {
		name: "feed",
		components: {
            HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
			NotesFeed: () => import('@/components/Dedicated/Notes/Feed')
		}
	}
</script>
